<template>
  <div class='icon-generate'>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.01" height="20.935" viewBox="0 0 17.01 20.935">
      <g id="trash" transform="translate(-48)">
        <path id="Path_38107" data-name="Path 38107" d="M63.374,2.617h-3.6V1.963A1.963,1.963,0,0,0,57.813,0H55.2a1.963,1.963,0,0,0-1.963,1.963v.654h-3.6A1.636,1.636,0,0,0,48,4.252V5.561a.654.654,0,0,0,.654.654h15.7a.654.654,0,0,0,.654-.654V4.252A1.636,1.636,0,0,0,63.374,2.617Zm-8.832-.654a.655.655,0,0,1,.654-.654h2.617a.655.655,0,0,1,.654.654v.654H54.542Z" :fill="color"/>
        <path id="Path_38108" data-name="Path 38108" d="M73.569,184a.2.2,0,0,0-.2.214l.54,11.328a1.96,1.96,0,0,0,1.96,1.869H85.8a1.96,1.96,0,0,0,1.96-1.869l.54-11.328a.2.2,0,0,0-.2-.214Zm9.88,1.636a.654.654,0,1,1,1.308,0v8.5a.654.654,0,0,1-1.308,0Zm-3.271,0a.654.654,0,1,1,1.308,0v8.5a.654.654,0,0,1-1.308,0Zm-3.271,0a.654.654,0,1,1,1.308,0v8.5a.654.654,0,0,1-1.308,0Z" transform="translate(-24.327 -176.476)" :fill="color"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconTrash',
  props: {
    color: {
      type: String,
      required: false,
      default: '#5e5e5e',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
